<template>
  <CardTable
    ref="tableRef"
    :is-delete="false"
    name="Car Color"
    :header="tableHeader"
    endpoint="car-color"
    :breadcrumbs="breadcrumbs"
    :currentIndex="currentIndex"
    @changeIndex="selectedUpdate"
  >
    <template v-slot:cell-no="{ row }">
      {{ row.no }}
    </template>
    <template v-slot:cell-name="{ row, index }">
      <div v-if="currentIndex !== index">{{ row.name }}</div>
      <div class="d-flex justify-content-center" v-if="currentIndex === index">
        <input type="text" class="like-el-input w-150px" v-model="row.name" />
      </div>
    </template>
    <template v-slot:create>
      <button
        data-bs-toggle="modal"
        data-bs-target="#create"
        class="btn btn-success me-4"
        name="create color"
      >
        Create
      </button>
    </template>
  </CardTable>

  <div
    ref="modalRef"
    class="modal fade"
    id="create"
    tabindex="-1"
    aria-labelledby="create"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header fs-3">Create New Color</div>
        <div class="modal-body px-12">
          <form @submit.prevent="null">
            <el-input
              type="text"
              class="mb-3"
              placeholder="Color"
              v-model="inputRef.name"
            />
            <div class="d-flex justify-content-end">
              <button @click="create()" class="btn btn-primary">Confirm</button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { reactive, ref } from 'vue';
import CardTable from '@/components/kt-datatable/CardTable.vue';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import ApiService from '@/core/services/ApiService';
import { hideModal } from '@/core/helpers/dom';
import { reinitializeComponents } from '@/core/plugins/keenthemes';

export default {
  name: 'ManageColor',
  components: {
    CardTable,
  },
  setup() {
    const breadcrumbs = ['Car & Function', ['Manage Car Color']];
    const tableHeader = [
      {
        key: 'no',
        name: 'No.',
        sortable: true,
      },
      {
        name: 'Color',
        key: 'name',
        sortable: true,
      },
      {
        name: 'Actions',
        key: 'actions',
        sortable: false,
      },
    ];

    const currentIndex = ref(-1);
    let inputRef = reactive({
      name: '',
    });
    const modalRef = ref(null);
    const tableRef = ref(null);

    const create = async () => {
      await ApiService.post('car-color', {
        name: inputRef.name,
      });

      hideModal(modalRef.value);

      Swal.fire({
        text: 'New Color has been created',
        icon: 'success',
        buttonsStyling: false,
        confirmButtonText: 'Return to Color Lists',
        customClass: {
          confirmButton: 'btn btn-success',
        },
      });

      inputRef.name = '';

      await tableRef.value.getData(
        tableRef.value.currentPage,
        tableRef.value.rowsPerPage
      );

      reinitializeComponents();
    };

    const selectedUpdate = (index) => {
      currentIndex.value = index;
    };

    return {
      tableHeader,
      breadcrumbs,
      currentIndex,
      inputRef,
      modalRef,
      create,
      tableRef,
      selectedUpdate,
    };
  },
};
</script>
